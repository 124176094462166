import React from "react"
import get from "lodash/get"
import styles from "../sponsors.module.css"
import ScrollAnimation from "react-animate-on-scroll"

export default ({ data }) => (
  <>
    <ScrollAnimation animateIn="fadeIn">
      <div className="container">
        {/* <div className="row d-flex justify-content-center mb-2">
          <div className={`col-12 main_logo ${styles.winner_ones}`}>
            <div className="card main-card-style">
              <img src="/ZepRe.png" alt="Faraja Rafting Challenge" />
            </div>
          </div>
        </div> */}
        <div className="row logo-row">
          <div className={`col-6 ${styles.winner_ones}`}>
            <div className="card logo-card-style">
              <img
                src={data?.photos[4]?.sizes?.src}
                alt="Faraja Rafting Challenge"
              />
            </div>
          </div>
          <div className={`col-6 ${styles.winner_ones}`}>
            <div className="card logo-card-style">
              <img
                src={data?.photos[2]?.sizes?.src}
                alt="Faraja Rafting Challenge"
              />
            </div>
          </div>
        </div>
        <div className="row logo-row">
          <div className={`col-6 ${styles.winner_ones}`}>
            <div className="card logo-card-style">
              <img
                src={data?.photos[3]?.sizes?.src}
                alt="Faraja Rafting Challenge"
              />
            </div>
          </div>
          <div className={`col-6 ${styles.winner_ones}`}>
            <div className="card logo-card-style">
              <img
                src={data?.photos[1]?.sizes?.src}
                alt="Faraja Rafting Challenge"
              />
            </div>
          </div>
        </div>
        <div className="row logo-row justify-content-center">
            <div className={`col-6 ${styles.winner_ones}`}>
              <div className="card logo-card-style">
                <img
                  src={data?.photos[5]?.sizes?.src}
                  alt="Faraja Rafting Challenge"
                />
              </div>
            </div>
          </div>
      </div>
    </ScrollAnimation>
  </>
)
